* {
  box-sizing: border-box;
}

html {
  font-size: 10px;
  position: relative;
  min-height: 100vh;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'favorit', 'times-now', serif;
  letter-spacing: 0.05rem;
  font-size: 1.5rem;
  color: #000;
  font-weight: 400;
  cursor: default;
}

a,
a:focus,
a:visited,
a:active {
  color: #000;
  font-style: italic;
  text-decoration: none;
}

a:hover {
  cursor: crosshair;
}

.invisible {
  display: none;
}

.page-wrapper h2 {
  text-transform: uppercase;
  font-weight: 700;
  margin: 0 0 3px;
}

@media only screen and (max-width: 991px) {
  .page-wrapper h2 {
    margin-bottom: 0;
  }
}

.page-wrapper h2,
.page-wrapper li {
  font-size: 11px;
  letter-spacing: 0.09px;
  text-rendering: optimizeLegibility;
}

nav li {
  margin-bottom: 3px;
}
