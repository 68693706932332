.image-wrapper {
  display: inline-block;
  height: 0;
  position: relative;
  margin: 0;
  overflow: hidden;
  background-color: #f4f4f4;
}

.image-wrapper p {
  position: absolute;
  bottom: -30px;
}

@media only screen and (max-width: 991px) {
  .image-wrapper {
    margin: 40px 0 0;
  }
}

.image-wrapper img {
  position: absolute;
  width: 100%;
}

.ready {
  filter: blur(34px);
}
