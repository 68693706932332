body {
  font-family: sans-serif;
}

.app {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 85px;
}

@media only screen and (max-width: 991px) {
  .app {
    padding-top: 50px;
  }
}

header {
  padding: 10px 0 15px;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
}

.header-wrapper {
  width: 60%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

@media only screen and (max-width: 991px) {
  .header-wrapper {
    width: 80%;
  }
}

header a {
  font-style: italic;
}

header a:hover {
  font-style: normal;
}

header h1 {
  text-transform: uppercase;
  font-size: 2.6rem;
}

header h1,
header p {
  margin: 0;
}

header span {
  word-break: keep-all;
  display: inline-block;
}

@media only screen and (max-width: 767px) {
  .header-wrapper {
    flex-direction: column;
    width: 100%;
    text-align: center;
  }
}

section p {
  margin: 0;
}

.row {
  width: 100%;
  margin-bottom: 50px;
}

section {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 0 50px;
  flex-grow: 1;
  z-index: 0;
  padding: 0 15vw;
}

.page-wrapper {
  display: flex;
  padding: 0 55px;
  width: 100%;
}

@media only screen and (max-width: 1299px) {
  section {
    padding: 0 195px;
  }
}

@media only screen and (max-width: 991px) {
  nav {
    display: none;
  }

  .page-wrapper {
    padding: 0 10px;
  }

  section {
    padding: 0;
  }
}

nav {
  flex-shrink: 0;
}

nav article {
  margin-bottom: 40px;
}

.page-wrapper li {
  list-style-type: none;
}

.fixed-sidebar {
  position: fixed;
  width: 200px;
}

.fixed-sidebar.description {
  right: 55px;
  padding-left: 20px;
}

@media only screen and (max-width: 991px) {
  .page-wrapper {
    flex-direction: column-reverse;
  }

  .fixed-sidebar.description {
    position: relative;
    right: 0;
    padding: 0;
    width: 100%;
  }
}

.info {
  font-size: 19px;
  font-weight: 400;
  padding-left: 75px;
  position: relative;
  margin: 15px 0 0;
}

.info .title {
  line-height: 1;
  text-transform: uppercase;
}

.info .episodeData,
.quote {
  font-family: 'times-now';
}

.info .episodeData {
  width: 75px;
  display: inline-block;
  position: absolute;
  left: 0;
  bottom: 2px;
  line-height: 1;
}

.quote {
  font-size: 20px;
}
